import leftPad from 'left-pad';
import moment from 'moment-mini';
import request from '../request';
import analytics from '../analytics';

const baseState = {
  loadingList: false,
  loadedList: false,
  loadingSingle: true,
  data: {
    lifetime: {
      start: null,
      end: null,
    },
    eventIdsPerVenue: {},
    eventOrder: [],
    ticketsForDate: [],
    timeslotsOrder: [],
    notFoundSingle: false,
    events: [],
    venues: [],
    ticketGroups: {},
    groups: [],
    soldoutDates: {},
    singleCalendar: [],
    maxTicketsForSchedule: null,
    timeslotsForDate: {},
  },
  form: {
    eventId: null,
    date: undefined,
    tickets: {},
    ticketCount: 0,
    time: null,
    totalPrice: 0,
    timeslotId: null,
  },
};

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(baseState)),
  mutations: {
    clearForm(state) {
      state.form = JSON.parse(JSON.stringify(baseState.form));
    },
    clearTickets(state) {
      state.data.schedule = [];
      state.data.ticketsForDate = [];
    },
    clearTotals(state) {
      state.form.ticketCount = 0;
      state.form.totalPrice = 0;
    },
    setEventId(state, id) {
      Object.assign(state.form, { eventId: id });
    },
    setLifetime(state, data) {
      state.data.lifetime.start = data[0];
      state.data.lifetime.end = data[1];
    },
    setLoadingList(state, data) {
      Object.assign(state, { loadingList: data });
    },
    setLoadingSingle(state, loadingSingle) {
      Object.assign(state, { loadingSingle });
    },
    setNotFoundSingle(state, notFoundSingle) {
      Object.assign(state, { notFoundSingle });
    },

    setEventList(state, payload) {
      state.data.events = payload;
      const idsPerVenue = {};
      Object.keys(payload).forEach((key) => {
        if (typeof idsPerVenue[payload[key].venue] === 'undefined') {
          idsPerVenue[payload[key].venue] = [];
        }
        idsPerVenue[payload[key].venue].push(key);
        if (typeof payload[key].custom_groups === 'undefined') {
                    payload[key].custom_groups = []; // eslint-disable-line
        }
      });
      state.data.eventIdsPerVenue = idsPerVenue;

      const eventsWithSeqNumber = [];
      const eventsWithDate = [];
      Object.keys(payload).forEach((key) => {
        const e = payload[key];
        if (e.sequence_no !== '0') {
          eventsWithSeqNumber.push(key);
        } else {
          eventsWithDate.push(key);
        }
      });

      const eventOrderNumbers = eventsWithSeqNumber.sort(
        (a, b) => parseInt(payload[a].sequence_no, 10) - parseInt(payload[b].sequence_no, 10),
      );

      const eventOrderDates = eventsWithDate.sort((a, b) => new Date(payload[a].sorting_datetime)
                - new Date(payload[b].sorting_datetime));
      state.data.eventOrder = [...eventOrderNumbers, ...eventOrderDates];
    },

    setTicketsForDate(state, data) {
      const orderedTickets = data.tickets.sort((a, b) => parseInt(a.sequence_no, 10) > parseInt(b.sequence_no, 10));
      const tickets = orderedTickets.filter((t) => {
        if (t.mode === '5' || t.mode === '1') {
          const availableTicket = t.schedule.find((s) => s.max_tickets > 0);
          if (typeof availableTicket === 'undefined') {
            return false;
          }
        }
        return true;
      });
      tickets.forEach((t, i) => {
        if (t.family && t.subtickets.length >= 2) {
          let subticketWithFixedCount = null;
          let subticketWithoutFixedCount = null;
          t.subtickets.forEach((st, j) => {
            if (parseInt(st.max_quantity, 10) === parseInt(st.min_quantity, 10)) {
              subticketWithFixedCount = j;
            } else {
              subticketWithoutFixedCount = j;
            }
          });
          if (Number.isInteger(subticketWithFixedCount) && Number.isInteger(subticketWithoutFixedCount)) {
            tickets[i].subtickets[subticketWithFixedCount].hasFixedCount = true;
          }
        }
      });
      state.data.ticketsForDate = tickets;
    },
    clearTicketGroups(state) {
      state.data.ticketGroups = {};
    },
    setTicketGroups(state, data) {
      const { tickets } = data;
      const ticketGroups = {};
      tickets.forEach((t) => {
        if (typeof t.group_id !== 'undefined' && t.group_id != null) {
          ticketGroups[t.group_id] = { name: t.group_name, selected: false };
        }
      });
      state.data.ticketGroups = ticketGroups;
    },
    setMaxTicketsForSchedule(state) {
      const mode3Or5Ticket = state.data.ticketsForDate.find((t) => (t.mode === '3' || t.mode === '5'));
      if (typeof state.data.timeslotsForDate[state.form.timeslotId] !== 'undefined' && typeof mode3Or5Ticket === 'undefined') {
        state.data.maxTicketsForSchedule = state.data.timeslotsForDate[state.form.timeslotId].max_tickets;
      } else {
        state.data.maxTicketsForSchedule = null;
      }
    },
    setSingle(state, dates) {
      if (Array.isArray(dates)) {
        const newCalendar = {};
        dates.forEach((date) => {
          newCalendar[date] = {};
        });
        state.data.singleCalendar = newCalendar;
      }
    },
    setSoldoutDates(state, dates) {
      const soldoutDays = {};
      dates.forEach((d) => {
        soldoutDays[d] = true;
      });
      state.data.soldoutDates = soldoutDays;
    },
    setFormDate(state, date) {
      state.form.date = date;
    },
    setFormTime(state, timeslotId) {
      const timeslot = state.data.timeslotsForDate[timeslotId];
      if (typeof timeslot !== 'undefined') {
        state.form.time = timeslot.datetime;
        state.form.timeslotId = timeslotId;
      }
    },
    setVenueList(state, eventList) {
      const venueNames = [];
      const venues = [];
      Object.keys(eventList).forEach((key) => {
        if (!venueNames.includes(eventList[key].venue)) {
          venueNames.push(eventList[key].venue);
          venues.push({ name: eventList[key].venue, selected: false });
        }
      });
      venues.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()));
      state.data.venues = venues;
    },
    setCustomGroupList(state, eventList) {
      const groupNames = [];
      const groups = [];
      Object.keys(eventList).forEach((key) => {
        const event = eventList[key];
        event.custom_groups.forEach((cg) => {
          if (!groupNames.includes(cg.name)) {
            groupNames.push(cg.name);
            groups.push({ id: cg.id, name: cg.name, selected: false });
          }
        });
      });
      state.data.groups = groups;
    },
    setFormTicketCount(state, { id, count }) {
      const newTicketsObj = state.form.tickets;
      if (typeof count !== 'undefined') {
        if (typeof newTicketsObj[id] === 'undefined') {
          newTicketsObj[id] = {};
        }
        newTicketsObj[id].count = count;
        state.form.tickets = newTicketsObj;
      } else if (state.form.tickets[id].subTickets !== 'undefined') {
        const hasSubtickets = Object.keys(state.form.tickets[id].subTickets)
          .find((key) => (state.form.tickets[id].subTickets[key].count !== 0));
        if (typeof hasSubtickets !== 'undefined') {
          newTicketsObj[id].count = 1;
        } else {
          newTicketsObj[id].count = 0;
        }
        state.form.tickets = newTicketsObj;
      }
    },
    setTicketsForm(state) {
      const newTicketsForm = {};
      state.data.ticketsForDate.forEach((t) => {
        let subTickets;
        if (typeof t.subtickets !== 'undefined') {
          subTickets = {};
          t.subtickets.forEach((st) => {
            subTickets[st.id] = { count: 0, price: parseInt(st.price, 10), vat: parseInt(st.vat_value, 10) };
          });
        }
        newTicketsForm[t.id] = {
          print_separately: parseInt(t.print_separately, 10),
          count: 0,
          subTickets,
        };
        if (t.mode === '5') {
          if (typeof t.schedule !== 'undefined') {
            if (t.schedule.length !== 0) {
              newTicketsForm[t.id].datetime = t.schedule[0].datetime;
              newTicketsForm[t.id].datetime_end = t.schedule[0].datetime_end;
            }
          }
        }
      });
      state.form.tickets = newTicketsForm;
    },
    setTotalTicketCount(state) {
      let count = 0;
      if (Object.keys(state.form.tickets).length > 0) {
        Object.keys(state.form.tickets).forEach((ticket) => {
          count += state.form.tickets[ticket].count;
        });
      }
      if (Number.isNaN(count)) {
        count = 0;
      }
      state.form.ticketCount = count;
    },
    setTotalPrice(state) {
      let totalPrice = 0;
      const ticketIds = Object.keys(state.form.tickets);
      ticketIds.forEach((ticketId) => {
        const ticketData = state.data.ticketsForDate
          .find((t) => parseInt(t.id, 10) === parseInt(ticketId, 10));
        const price = parseInt(ticketData.price, 10)
                    * state.form.tickets[ticketId].count;
        totalPrice += price;
        if (typeof state.form.tickets[ticketId].subTickets !== 'undefined') {
          Object.keys(state.form.tickets[ticketId].subTickets).forEach((subTicketId) => {
            const subTicket = state.form.tickets[ticketId].subTickets[subTicketId];
            if (subTicket.count > 0) {
              totalPrice += subTicket.price * subTicket.count;
            }
          });
        }
      });
      if (Number.isNaN(totalPrice)) {
        totalPrice = 0;
      }
      state.form.totalPrice = totalPrice;
    },
    setLoadedList(state, data) {
      Object.assign(state, { loadedList: data });
    },
    setSubTicketCount(state, { ticketId, subTicketId, count }) {
      Object.assign(state.form.tickets[ticketId].subTickets[subTicketId], { count });
    },
    setFixedSubticketsCount(state, ticketId) {
      const formTicket = state.form.tickets[ticketId];
      const dataTicket = state.data.ticketsForDate.find((t) => parseInt(t.id, 10) === ticketId);
      let totalSubTicketCount = 0;
      Object.keys(formTicket.subTickets).forEach((k) => {
        totalSubTicketCount += formTicket.subTickets[k].count;
      });
      dataTicket.subtickets.forEach((st) => {
        if (st.hasFixedCount) {
          let count = 0;

          if ((totalSubTicketCount - state.form.tickets[ticketId].subTickets[st.id].count) !== 0) {
            count = parseInt(st.max_quantity, 10);
          }
          const newSubticket = { count, price: parseInt(st.price, 10), vat: parseInt(st.vat_value, 10) };
          state.form.tickets[ticketId].subTickets[st.id] = newSubticket;
        }
      });
    },
    setGroupFilter(state, payload) {
      Object.assign(state.data.groups[payload.key], { selected: payload.selected });
    },
    setTicketGroupFilter(state, payload) {
      Object.keys(state.data.ticketGroups).forEach((key) => {
        Object.assign(state.data.ticketGroups[key], { selected: false });
      });
      Object.assign(state.data.ticketGroups[payload.key], { selected: payload.selected });
    },
    setVenueFilter(state, payload) {
      Object.assign(state.data.venues[payload.key], { selected: payload.selected });
    },
    setTimeslotsForDate(state, payload) {
      const timeslotsOrder = [];
      const schedules = [];

      payload.tickets.forEach((t) => {
        if (parseInt(t.mode, 10) === 1) {
          if (typeof t.schedule !== 'undefined') {
            t.schedule.forEach((s) => {
              if (!schedules.includes(s.datetime)) {
                schedules.push(s.datetime);
              }
            });
          }
        }
      });
      const newTimeslots = {};
      Object.keys(payload.timeslot).forEach((k) => {
        const t = payload.timeslot[k];
        if (schedules.includes(t.datetime)) {
          newTimeslots[k] = t;
        }
      });
      schedules.sort();
      schedules.forEach((s) => {
        const timeslotId = Object.keys(payload.timeslot).find(
          (k) => payload.timeslot[k].datetime === s,
        );
        timeslotsOrder.push(timeslotId);
      });

      state.data.timeslotsForDate = newTimeslots;
      state.data.timeslotsOrder = timeslotsOrder;
    },
  },
  actions: {
    addToCart(context) {
      return new Promise((resolve) => {
        const ticketKeys = Object.keys(context.state.form.tickets);
        ticketKeys.forEach((ticketId) => {
          const ticket = context.state.form.tickets[ticketId];

          if (ticket.count !== 0) {
            const ticketData = context.state.data.ticketsForDate
              .find((t) => parseInt(t.id, 10) === parseInt(ticketId, 10));

            let subtickets;
            let datetime = '';
            let datetimeEnd;

            if (parseInt(ticketData.mode, 10) !== 1 ) {
              datetime += context.state.form.date;
            } else {
              if (typeof ticket.datetime !== 'undefined') {
                datetime = ticket.datetime;
              } else {
                if (context.state.form.date) {
                  datetime += context.state.form.date;
                }
                if (context.state.form.time) {
                  datetime = context.state.form.time;
                }
              }
              if (typeof ticket.datetime_end !== 'undefined') {
                datetimeEnd = ticket.datetime_end;
              }
            }
            if (ticketData.mode === '3') {
              [datetimeEnd] = context.state.data.events[context.state.form.eventId].end.split(' ');
            }

            if (typeof ticket.subTickets !== 'undefined') {
              subtickets = [];
              const subTicketKeys = Object.keys(ticket.subTickets);
              subTicketKeys.forEach((k) => {
                const subTicketData = ticketData.subtickets.find((st) => st.id === k);

                subtickets.push({
                  id: k,
                  count: ticket.subTickets[k].count,
                  name: subTicketData.name,
                  price: ticket.subTickets[k].price,
                  vat: ticket.subTickets[k].vat,
                });
              });
            }
            let totalPrice = 0;
            totalPrice = parseInt(ticketData.price, 10) * ticket.count;
            if (typeof ticket.subTickets !== 'undefined') {
              Object.keys(ticket.subTickets).forEach((k) => {
                const subTicket = ticket.subTickets[k];
                totalPrice += subTicket.price * subTicket.count;
              });
            }

            const cartTicket = {
              totalCount: ticket.count,
              datetime,
              datetime_end: datetimeEnd,
              eventName: context.state.data.events[ticketData.eventId].name,
              id: ticketId,
              name: ticketData.name,
              mode: ticketData.mode,
              type: 'ticket',
              bookWithCost: !!parseInt(ticketData.book_with_cost, 10),
              bookWithCostWarning: ticketData.book_with_cost_warn,
              price: parseInt(ticketData.price, 10),
              totalPrice,
              tax: ticketData.vat_value,
              print_separately: ticket.print_separately,
              subtickets,
            };
            analytics.addTicketToCart(cartTicket);
            context.dispatch('cart/add', cartTicket, { root: true });
          }
        });
        resolve();
      });
    },
    clearForm(context) {
      context.commit('clearForm');
    },
    clearTickets(context) {
      return new Promise((resolve) => {
        context.commit('clearTickets');
        resolve();
      });
    },
    setEventId(context, payload) {
      context.commit('setEventId', payload);
    },
    getList(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadingList', true);
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.EVENT_LIST}`;
        request.make({
          type: 'GET',
          url,
        }, (err, res) => {
          context.commit('setLoadingList', false);
          let wasLoaded = true;
          let hasData = false;
          // Checking is response is valid
          if (err !== null) wasLoaded = false;
          if (res.status !== 200) wasLoaded = false;
          if (typeof res.body !== 'undefined') {
            if (typeof res.body.data !== 'undefined') {
              if (Object.keys(res.body.data).length > 0) {
                hasData = true;
              }
            }
          }
          if (!hasData) wasLoaded = false;
          if (wasLoaded) {
            context.commit('setEventList', res.body.data);
            context.commit('setVenueList', res.body.data);
            context.commit('setCustomGroupList', res.body.data);
            context.commit('setLoadedList', true);
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    setLoadingSingle(context, payload) {
      context.commit('setLoadingSingle', payload);
    },
    getTicketList(context, eventId) {
      return new Promise((resolve) => {
        context.commit('setLoadingSingle', true);
        context.commit('clearTotals');
        context.commit('clearTicketGroups');
        const { app } = context.rootState;
        const { date } = context.state.form;
        let dateURL = `/date/${date}`;
        if (typeof date === 'undefined') {
          dateURL = '';
        }
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.TICKET_LIST}${eventId}${dateURL}`,
        }, (err, res) => {
          if (err === null) {
            context.commit('setTicketsForDate', res.body.data);
            context.commit('setTicketGroups', res.body.data);
            context.commit('setTimeslotsForDate', res.body.data);
            let shouldSetDefaultTime = true;
            if (typeof res.body.data.timeslot !== 'undefined') {
              if (Object.keys(res.body.data.timeslot).length > 0) {
                if (context.state.form.time !== null) {
                  const slotInNewSchedule = Object.keys(res.body.data.timeslot).find(
                    (k) => {
                      const s = res.body.data.timeslot[k];
                      const timeslotTime = s.datetime.split(' ')[1];
                      const formTime = context.state.form.time.split(' ')[1];
                      return timeslotTime === formTime;
                    },
                  );
                  if (typeof slotInNewSchedule !== 'undefined') {
                    context.commit('setFormTime', slotInNewSchedule);
                    shouldSetDefaultTime = false;
                  }
                }
                if (shouldSetDefaultTime) {
                  const k = context.state.data.timeslotsOrder[0];
                  context.commit('setFormTime', k);
                }
              }
            }
            context.commit('setMaxTicketsForSchedule');
            context.commit('setTicketsForm');
            context.commit('setLoadingSingle', false);
            resolve();
          }
        });
      });
    },
    getLifetime(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        const { eventId } = context.state.form;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.EVENT_LIFETIME}?event=${eventId}`;
        request.make({
          type: 'GET',
          url,
        }, (err, res) => {
          if (res.status === 200) {
            context.commit('setLifetime', res.body);
            resolve();
          }
        });
      });
    },
    getSingleCalendar(context, { exhibition, date, endDate }) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        context.commit('setLoadingSingle', true);
        context.commit('setNotFoundSingle', false);

        const dayNumber = leftPad(date.date(), 2, 0);
        const monthNumber = leftPad(date.month() + 1, 2, 0);
        const year = date.year();
        const dateStr = `${year}-${monthNumber}-${dayNumber}`;
        const startDate = date.startOf('month').format('YYYYMMDD');
        let endD = date.add(1, 'M').endOf('month').format('YYYYMMDD');
        if (typeof endDate !== 'undefined') {
          endD = endDate.format('YYYYMMDD');
        }
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.EVENT_CALENDAR}${exhibition}/range/${startDate};${endD}?deep=1`;
        request.make({
          type: 'GET',
          url,
        }, (err, res) => {
          context.commit('setLoadingSingle', false);
          if (res.status === 200) {
            if (typeof res.body !== 'undefined') {
              // set current or first available date
              if (typeof res.body.data !== 'undefined') {
                const dates = Object.keys(res.body.data).filter((d) => res.body.data[d] > 0);
                const soldoutDates = Object.keys(res.body.data).filter((d) => res.body.data[d] === 0);
                context.commit('setSingle', dates);
                context.commit('setSoldoutDates', soldoutDates);
                let d = null;
                if (['gh', 'gh2'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
                  // for client GH simply set the current date; the hint is disired by client if this day will sold out
                  d = dateStr;
                } else {
                  if (dates > 0) {
                    [d] = dates;
                  }
                  if (dates.includes(dateStr)) {
                    d = dateStr;
                  } else if (Array.isArray(dates)) {
                    [d] = dates;
                    if (dates.includes(dateStr)) {
                      d = dateStr;
                    }
                  }
                }
                if (typeof d === 'undefined') {
                  d = date.startOf('month').format('YYYY-MM-DD');
                }

                if (typeof context.state.form.date === 'undefined' || moment(d).month() !== moment(context.state.form.date).month()) {
                  context.dispatch('setFormDate', d);
                }

                resolve();
              }
            }
          } else {
            context.commit('setNotFoundSingle', true);
            resolve();
          }
        });
      });
    },
    setFormDate(context, date) {
      context.commit('setFormDate', date);
      if (typeof context.state.data.singleCalendar[date] !== 'undefined') {
        const keys = Object.keys(context.state.data.singleCalendar[date]);
        if (keys.length === 1) {
          // There's just one time slot so we can pre-select it
          context.dispatch('setFormTime', keys[0]);
        }
      }
    },
    setFormTicketCount(context, payload) {
      if (context.state.data.maxTicketsForSchedule === null || context.state.data.maxTicketsForSchedule >= payload.count) {
        context.commit('setFormTicketCount', payload);
        context.commit('setTotalTicketCount');
        context.commit('setTotalPrice');
      }
    },
    setFormSubTicketCount(context, data) {
      let maxTicketsForSchedule = null;
      if (typeof context.state.data.timeslotsForDate[context.state.form.timeslotId] !== 'undefined') {
        maxTicketsForSchedule = context.state.data
          .timeslotsForDate[context.state.form.timeslotId].max_tickets;
      }
      let newSubTicketCount = 0;
      Object.entries(context.state.form.tickets[data.ticketId].subTickets).forEach((e) => {
        if (e[0] === data.subTicketId) {
          newSubTicketCount += data.count;
        } else {
          newSubTicketCount += e[1].count;
        }
      });

      if (maxTicketsForSchedule === null || maxTicketsForSchedule >= newSubTicketCount) {
        context.commit('setSubTicketCount', data);
        context.commit('setFormTicketCount', { id: data.ticketId });
        context.commit('setTotalTicketCount');
        context.commit('setTotalPrice');
      }
      context.dispatch('setFixedSubticketsCount', data.ticketId);
    },
    setFixedSubticketsCount(context, ticketId) {
      context.commit('setFixedSubticketsCount', ticketId);
      context.commit('setFormTicketCount', { id: ticketId });
      context.commit('setTotalTicketCount');
      context.commit('setTotalPrice');
    },
    setTicketsForDateFormTime(context, timeslotId) {
      context.commit('setFormTime', timeslotId);
      context.commit('setMaxTicketsForSchedule');
      context.commit('setTicketsForm');
      context.commit('setTotalTicketCount');
    },
    setFormTime(context, time) {
      context.commit('setFormTime', time);
      context.commit('setMaxTicketsForSchedule');
      context.commit('setTicketsForm');
      context.commit('setTotalTicketCount');
    },
    setVenueFilter(context, payload) {
      context.commit('setVenueFilter', payload);
    },
    setGroupFilter(context, payload) {
      context.commit('setGroupFilter', payload);
    },
    setTicketGroupFilter(context, payload) {
      return new Promise((resolve) => {
        context.commit('setTicketGroupFilter', payload);
        resolve();
      });
    },
  },
  getters: {
    filteredEvents: (state) => {
      const filteredVenues = state.data.venues
        .filter((venue) => venue.selected);
      const filteredGroups = state.data.groups
        .filter((group) => group.selected);
      const enabledVenuesCount = filteredVenues.length;
      const enabledVenueNames = [];
      filteredVenues.forEach((v) => {
        enabledVenueNames.push(v.name);
      });
      const enabledGroupNames = [];
      filteredGroups.forEach((v) => {
        enabledGroupNames.push(v.name);
      });

      const list = {};
      Object.keys(state.data.events).forEach((key) => {
        let shouldAdd = true;

        const evVenue = state.data.events[key].venue;
        const evGroups = state.data.events[key].custom_groups;

        if (enabledGroupNames.length > 0 && state.data.groups.length !== 0) {
          let isPartOfAnyGroup = false;
          evGroups.forEach((evGroup) => {
            window.e = enabledGroupNames;
            if (enabledGroupNames.includes(evGroup.name)) {
              isPartOfAnyGroup = true;
            }
          });
          if (!isPartOfAnyGroup) {
            shouldAdd = false;
          }
        }
        if (enabledVenuesCount !== 0 && !enabledVenueNames.includes(evVenue)) {
          shouldAdd = false;
        }

        if (shouldAdd) {
          list[key] = state.data.events[key];
        }
      });
      return list;
    },
  },
};
